import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '../snackbar/snackbar-error/snackbar-error.component';
import { SnackbarSuccessComponent } from '../snackbar/snackbar-success/snackbar-success.component';

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {

	constructor(private snackbar: MatSnackBar) {}

	success(message?: string) {
		let snackBarRef: any;
		let config = new MatSnackBarConfig;
		message = message ? message : 'Success!';
		config.duration = 7000;
		config.data = {};
		config.data.message = message;
		config.panelClass = ['mat-toolbar', 'mat-snack', 'mat-success']
		snackBarRef = this.snackbar.openFromComponent(SnackbarSuccessComponent, config);
		snackBarRef.instance.snackBarRef = snackBarRef;
	}

	error() {
		let snackBarRef: any;
		let config = new MatSnackBarConfig;
		config.duration = 20000;
		config.panelClass = ['mat-toolbar', 'mat-snack']
		snackBarRef = this.snackbar.openFromComponent(SnackbarErrorComponent, config);
		snackBarRef.instance.snackBarRef = snackBarRef;
	}
}