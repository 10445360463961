import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class SEOService {
	private baseUrl = 'https://aaasepticpro.com';

	constructor(
		private title: Title,
		private meta: Meta
	) { }

	updateTags(data: any, routerUrl: string) {
		this.title.setTitle(data.title)

		if (data.description) {
			this.meta.updateTag({ name: 'description', content: data.description })
		}
		// else {
		// 	this.meta.removeTag("name='description'");
		// }

		// Handled by index file replacements
		// if (data.robots) {
		// 	this.meta.updateTag({ name: 'robots', content: data.robots })
		// } else {
		// 	this.meta.updateTag({ name: 'robots', content: environment.robotMeta })
		// 	this.meta.updateTag({ name: 'googlebot', content: environment.robotMeta })
		// }

		// Social media meta
		if (data.ogUrl) {
			this.meta.updateTag({ property: 'og:url', content: data.ogUrl })
		} else {
			this.meta.updateTag({ property: 'og:url', content: this.baseUrl + routerUrl })
		}

		if (data.ogTitle) {
			this.meta.updateTag({ property: 'og:title', content: data.ogTitle })
		}
		// else {
		// 	this.meta.removeTag("property='og:title'")
		// }

		if (data.ogDescription) {
			this.meta.updateTag({ property: 'og:description', content: data.ogDescription })
		}
		// else {
		// 	this.meta.removeTag("property='og:description'")
		// }

		// Lives in the index.html
		// if (data.ogImage) {
		// 	this.meta.updateTag({ property: 'og:image', content: data.ogImage })
		// }
	}
}
