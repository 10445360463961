import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	selector: 'app-snackbar-success',
	templateUrl: './snackbar-success.component.html',
	styleUrls: ['./snackbar-success.component.scss']
})
export class SnackbarSuccessComponent implements OnInit {
	public action: string;
	public snackBarRef: MatSnackBarRef<SnackbarSuccessComponent>;

	constructor(
		@Inject(MAT_SNACK_BAR_DATA)
		public data: any
	) {
		this.action = 'Dismiss';
	}

	ngOnInit(): void {	}

	dismiss(): void {
		this.snackBarRef.dismiss();
	}
}
