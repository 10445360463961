<div class="highlight">
  Whether you need a new septic system design, installation, repair or septic
  pumping, We do it all... We are experts in the septic service in Washington.
</div>
<div class="content-container">
  <div class="content">
    <p>
      We are licensed by both the State of Washington and the Health Department
      to provide septic repairs, inspections, system installations, and pumping.
      We know the importance of personalized service and want you to come to us
      for all your septic work. You can count on us to treat every situation
      with genuine care and attention. We’re licensed and insured for your
      protection and our professional staff are standing by to answer your
      questions and provide the help you need. We put our customers first. We
      listen to you and help you find answers to all of your Septic questions.
      Give us a call, we are here to help solve all of your Septic System needs.
    </p>
    <p>
      Open Monday through Friday, 7:30 AM - 3:00 PM <br />
      We accept cash, check, debit/credit (+3%). <br />
    </p>
    <p>
      <b>$50 No access/No Show Fee</b> <br />
      <b>$50 Fee for cancellations within 24hrs</b> <br />
    </p>
  </div>
</div>
<div fxLayout="column" fxLayoutAlign="top center" class="content-container alt">
  <div fxLayout="row" fxLayout.sm="column" class="content">
    <div fxFlex.lt-sm="100">
      <img src="../../assets/aaa_equipment.jpg" fxFill />
    </div>
  </div>
</div>
<div class="content-container">
  <div class="content">
    <p>
      We are located in Southwest Washington and are currently serving the
      Clark, Cowlitz, and Skamania counties.
    </p>
    <p>
      The hardest part of any project is finding someone reliable and honest to
      guide you through it. We know how valuable your time is, especially when
      you have a septic system problem and we strive to minimize the impact on
      you.
    </p>
    <p>
      Our company's goal is to provide outstanding service to our customers.
      From the time the appointment is scheduled until the job is completed, we
      strive to exceed customers' expectations. Our company prides itself on
      taking the extra time to educate our customers. A properly maintained
      septic tank can save lots of money and frustration!
    </p>
  </div>
</div>
