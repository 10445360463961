import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SEOService } from 'projects/shared/services/seo.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	services: string[];
	serviceAreas: string[];
	year: number;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private seo: SEOService
	) {
		this.year = new Date().getFullYear();
		this.services = [
			'Septic Repairs',
			'Sewer Hookups',
			'Septic System Inspection',
			'Septic System Installs',
			'Septic Pumping',
			'Septic Line Cleaning',
			'Repairs & Installation'
		];

		this.serviceAreas = [
			'Clark County',
			'Limited Cowlitz County',
			'Limited Skamania County'
		];

	}

	ngOnInit() {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe(() => {
			let rt = this.getChild(this.activatedRoute)
			rt.data.subscribe(data => {
				this.seo.updateTags(data, this.router.url);
			})
		})
	}

	private getChild(activatedRoute: ActivatedRoute) {
		if (activatedRoute.firstChild) {
			return this.getChild(activatedRoute.firstChild);
		} else {
			return activatedRoute;
		}
	}
}
