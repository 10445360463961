<mat-card class="section">
  <mat-card-content>
    <h2>Reach out to us directly</h2>
    &nbsp;&nbsp;&nbsp;&nbsp;<mat-icon class="align">phone</mat-icon>&nbsp;(360)
    687-8960<br /><br />
    &nbsp;&nbsp;&nbsp;&nbsp;<mat-icon class="align">watch_later</mat-icon
    >&nbsp;Monday - Friday, 7:30am - 3pm<br /><br />
    &nbsp;&nbsp;&nbsp;&nbsp;<mat-icon class="align">markunread_mailbox</mat-icon
    >&nbsp;PO Box 1615<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Battle
    Ground, WA 98604<br /><br />
    &nbsp;&nbsp;&nbsp;&nbsp;<mat-icon class="align">email</mat-icon>&nbsp;<a
      href="mailto:office@aaasepticpro.com"
      >office&#64;aaasepticpro.com</a
    ><br /><br />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <h4>
      * We accept cash, check, debit/credit (+3%) <br />
      * <span style="color: red">$50 No access/No Show Fee</span><br />
      * <span style="color: red">$50 Fee for cancellations within 24hrs</span>
    </h4>
  </mat-card-content>
</mat-card>
