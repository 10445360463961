<div class="content-container">
	<div class="content">
		<h3>
			What are the main components of a septic tank system?
		</h3>
		<p>
			The main components of a septic system are:
			<br><br>
			The Septic Tank<br>
			Pump Tank & Pump<br>
			Drain Field<br>
			Inlet and/or Outlet Baffles<br>
			Filters<br>
		</p>
	</div>
</div>
<div class="content-container alt">
	<div class="content">
		<h3>
			How often should my septic tank be pumped?
		</h3>
		<p>
			Your septic tank should generally be pumped every 8-20 years, if it needs pumping in less than 8 years it could be due to heavy usage of disposal or a lot of antibacterial.
		</p>
	</div>
</div>
<div class="content-container">
	<div class="content">
		<h3>
			How can I access my septic tank?
		</h3>
		<p>
			Each septic tank has 1-5 lids that will allow you access to the septic tank. The lids will either be risered to grade, or they will be slightly below the surface of the ground, and need to be exposed.
		</p>
	</div>
</div>
<div class="content-container alt">
	<div class="content">
		<h3>
			Should I use my garbage disposal?
		</h3>
		<p>
			We recommend not using a garbage disposal with a septic system. The use of a garbage disposal will dramatically effect the amount of scum and sludge produced, and will significantly increase your need for pumping.
		</p>
	</div>
</div>
<div class="content-container">
	<div class="content">
		<h3>
			Is it okay for me to build over or do any other kind of landscaping on my drain field?
		</h3>
		<p>
			We recommend not building or landscaping over any part of your drain field or your septic system. These can cause costly damage to occur to the system.
		</p>
	</div>
</div>
