import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { SystemsComponent } from './systems/systems.component';
import { ResourcesComponent } from './resources/resources.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
	{
		path: 'about',
		component: AboutComponent,
		data: {
			title: 'AAA Septic Service',
		}
	},
	{
		path: 'systems',
		component: SystemsComponent,
		data: {
			title: 'Septic system types in Vancouver WA',
		}
	},
	{
		path: 'resources',
		component: ResourcesComponent,
		data: {
			title: 'Resoures for Septic Systems in Vancouver WA',
		}
	},
	{
		path: 'faq',
		component: FaqComponent,
		data: {
			title: 'Resoures for Septic Systems in Vancouver WA',
		}
	},
	{
		path: 'contact',
		component: ContactComponent,
		data: {
			title: 'Contact the best septic pumper in Vancouver WA',
		}
	},
	{ path: '**', redirectTo: 'about'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
