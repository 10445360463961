import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

// Third party imports
import { MaterialModule } from '../../../shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';

// Shared
// import { ContactModule } from '../../../shared/contact.module';

// Service imports
import { SEOService } from '../../../shared/services/seo.service';
import { LeadService } from '../../../shared/services/lead.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';

// Component imports
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { SystemsComponent } from './systems/systems.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { ResourcesComponent } from './resources/resources.component';
import { SnackbarSuccessComponent } from '../../../shared/snackbar/snackbar-success/snackbar-success.component';
import { SnackbarErrorComponent } from '../../../shared/snackbar/snackbar-error/snackbar-error.component';

const maskConfig: Partial<IConfig> = {
	validation: false
}

@NgModule({
	declarations: [
		AppComponent,
		AboutComponent,
		SystemsComponent,
		ContactComponent,
		FaqComponent,
		ResourcesComponent,
		SnackbarErrorComponent,
		SnackbarSuccessComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAnalyticsModule,
		AngularFirePerformanceModule,
		AngularFirestoreModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FlexLayoutModule,
		NgxMaskModule.forRoot(maskConfig),
		NgxCaptchaModule,
		// ContactModule
	],
	providers: [
		SEOService,
		LeadService,
		SnackBarService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
