import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Lead } from '../../../../shared/models/lead';
// import { LeadService } from '../../../../shared/services/lead.service';
import { SnackBarService } from '../../../../shared/services/snackbar.service';
// import { environment } from '../../environments/environment';
// import { ReCaptcha2Component } from 'ngx-captcha';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
	selector: 'contact-form',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	contactForm: FormGroup;
	submitted = false;
	// captcha = environment.captcha;

	// @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

	constructor(
		private formBuilder: FormBuilder,
		// private leadService: LeadService,
		private snackBarService: SnackBarService,
		private analytics: AngularFireAnalytics
		) {	}

	getErrorMessage(errors) {
		let messages = [];
		if (errors) {
			if (errors.required) {
				messages.push('You must enter a value');
			}
			if (errors.minlength) {
				messages.push('Not a valid phone number');
			}
			if (errors.email) {
				messages.push('Not a valid email');
			}
		}
		return messages;
	}

	ngOnInit() {
		this.contactForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			phone: ['', [Validators.required, Validators.minLength(10)]],
			email: ['', [Validators.email, Validators.required]],
			message: ['', [Validators.maxLength(6000), Validators.required]],
			recaptcha: ['', Validators.required]
		})
	}

	// Getter for form fields, used for validation
    get f() { return this.contactForm.controls; }

	onSubmit(formDirective: FormGroupDirective) {
		let lead: Lead;
		lead = this.contactForm.value;
		// Removes captcha so it is not stored in Firestore
		// delete lead['recaptcha'];

		// Track message submission events, capture character length for lack of something better to track
		this.analytics.logEvent('submit_message', {
			message_length: lead.message.length
		});

		// this.leadService.create(lead)
		// 	.then(() => {
		// 		formDirective.resetForm();
		// 		this.contactForm.reset();
		// 		this.captchaElem.resetCaptcha();
		// 		this.snackBarService.success('Your message has been submitted!');
		// 	})
		// 	.catch(() => {
		// 		this.snackBarService.error();
		// 	});
	}

}
