import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-resources',
	templateUrl: './resources.component.html',
	styleUrls: ['./resources.component.scss', '../app.component.scss']
})
export class ResourcesComponent implements OnInit {
	rmeDirections: string[];
	gisDirections: string[];

	constructor() {
		this.rmeDirections = [
			'Select the county you are located in.',
			'Enter your house number into the box labeled Street Number. Ex. "11234"',
			'Listed will be all 11234 house numbers and streets. Select the Property you would like to check the inspections on by clicking on the blue magnifying glass.',
			'Select sitework history on the left side of the page. (Small blue letters)',
			'Select OSS inspections or OSS pumpouts depending on what information your looking for.'
		];
		this.gisDirections = [
			'Enter your house number in the search box.',
			'Select your correct street from the search list below where you entered your address.',
			'Select the Layers tab from the yellow tabs at the top of the screen.',
			'On the right side of the screen below the Layers tab select the Enviromental Health Theme from the jump to drop down menu.',
			'Select the Legend tab (Next to the layers tab that you selected earlier) to view all the different symbols that you can see and what they mean.'
		]
	}

	ngOnInit(): void {
	}

}
