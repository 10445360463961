import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss', '../app.component.scss']
})
export class AboutComponent implements OnInit {
	emergencyServices: string[];

	constructor() { }

	ngOnInit(): void {
	}

}
