import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'app-snackbar-error',
	templateUrl: './snackbar-error.component.html',
	styleUrls: ['./snackbar-error.component.scss']
})
export class SnackbarErrorComponent implements OnInit {
	public action: string;
	public snackBarRef: MatSnackBarRef<SnackbarErrorComponent>;

	constructor( ) {
		this.action = 'Dismiss';
	}

	ngOnInit(): void {
	}

	dismiss(): void {
		this.snackBarRef.dismiss();
	}
}
