<div class="content-container">
	<div fxLayoutAlign="center center" class="content accordion">
		<mat-accordion class="info-cards no-select">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Gravity System
					</mat-panel-title>
					<mat-panel-description>
						Basic or standard system, uses gravity to move effluent through the septic tank and into the drainfield.
						<!-- <mat-icon>account_circle</mat-icon> -->
					</mat-panel-description>
				</mat-expansion-panel-header>
				<p>
					As the name implies, gravity drainfields work by letting gravity drain the effluent from the septic tank into a series of underground trenches. This means the drainfield area must be below the elevation of the septic tank. If this is not the case, then a pump is necessary and it is called a pump to standard gravity system.
				</p>
				<p>
					When you send new liquid waste into the septic tank, an equal amount of liquid (called effluent) comes out the outlet side of the tank. An “outlet filter” is installed in the tank to help prevent solids from escaping the tank.
				</p>
				<p>
					After passing through the outlet filter, the effluent flows through a distribution box (d-box) which diverts the flows to multiple pipes.
				</p>
				<p>
					The effluent leaves the d-box under the power of gravity and flows downhill to each of the underground drainage trenches. The trenches are made up of perforated pipe over drainrock. Each type effectively does the same thing…they allow the effluent to “perc” into the ground at the bottom of the trench.
				</p>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Pressure Distribution System
					</mat-panel-title>
					<mat-panel-description>
						Has a pump which distributes effluent throughout the drainfield at the same time.
					</mat-panel-description>
				</mat-expansion-panel-header>
				<p>
					Pressure distribution systems are usually required when there is less than optimal soil depth available for complete treatment of the effluent by a gravity system. A minimum of two feet of properly drained soil is required under the trenches. The tank and drainfield size are normally the same as a standard gravity system, but the method by which the effluent is distributed to the soil is different.
				</p>
				<p>
					Used in most new systems because it makes better use of the entire drainfield.
				</p>
				<p>
					A pump is used to pressurize the effluent into a small underground chamber which transports it to the drainfield. The drainfield itself consists of pipe and rock. Unlike a standard gravity system, a pressure distribution system wets the entire length of the trench each time the pump turns on. This allows the effluent to be spread over a larger area and receive better treatment from the soil.
				</p>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Mound System
					</mat-panel-title>
					<mat-panel-description>
						Installed when minimal soil is available for treatment, drainfield is raised above ground level.
						<!-- <mat-icon>looks</mat-icon> -->
					</mat-panel-description>
				</mat-expansion-panel-header>
				<p>
					Another system suited for sites with shallow soils is a sand mound. A mound is a drainfield raised above the natural soil surface with a sand fill material. Within the sand fill is a gravel bed with a network of pressurized pipes. Septic tank effluent is pumped through the pipes in controlled doses to insure uniform distribution throughout the bed. Treatment of the effluent occurs as it moves downward through the sand and directly into the natural soil beneath.
				</p>
				<p>
					They are also used when you have rocky soil because the effluent will run right through it without being treated.  Conversely, if the soil is too slow mounds can be used because a large portion of the effluent is returned to the atmosphere via evaporation so a mound will make up for slow soils.
				</p>
				<p>
					Sand mound septic systems use two tanks...the first tank is used as a normal tank (for settling of the solids) and the second tank has a pump in it that when the water level reaches a certain point or with a timer, the pump kicks on and pushes the effluent to the  mound...because of the small pipe and holes, the effluent is distributed (under pressure) evenly throughout the mound.
				</p>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Sand Filter System
					</mat-panel-title>
					<mat-panel-description>
						Has a sand filtering system and pump to treat and disburse effluent, used when minimal soil is available for treatment.
					</mat-panel-description>
				</mat-expansion-panel-header>
				<p>
					After the septic tank, a pump sends pressurized effluent to a large underground box which is full of sand and drainrock. Effluent is spread evenly over the surface of the sand via a pressurized pipe network (similar to a pressure distribution system). The dirty effluent filters through the sand and collects in a sump at the bottom. A second pump sends the relatively clean effluent to the drainfield where the final treatment occurs and the effluent is disposed in the soil.
				</p>
				<p>
					Since wastewater leaves a sand filter system as high-quality effluent, the soil in the trench or mound soil treatment system may be better able to accept it, and the system should last longer. Because sand filters produce cleaner wastewater, they are useful for sites that have been compacted, cut, or filled; and for environmentally sensitive areas like those near lakes, in shallow bedrock areas, aquifer recharge areas, and wellhead protection areas. Pretreatment may allow a reduction in the three-foot separation required between the soil treatment system and the limiting soil layer. Researchers in several states are testing reduced separation distances in soil treatment systems receiving wastewater pretreated in a sand filter
				</p>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header class="large">
					<mat-panel-title>
						(ATU) Aerobic Treatment Unit
					</mat-panel-title>
					<mat-panel-description>
						Uses oxygen to break down solids, producing cleaner wastewater than conventional types.
					<!-- <mat-icon>account_circle</mat-icon> -->
					</mat-panel-description>
				</mat-expansion-panel-header>
				<p>
					Often used in environmentally sensitive areas where effluent requires more treatment before entering the drainfield.
				</p>
				<p>
					Another method of treating wastewater involves an “aerobic” process by which air is injected into the effluent in a specific manner. The increased levels of oxygen in the effluent allow the microorganisms to thrive and digest the biological nutrients. A small air compressor is used to inject air into the effluent.
				</p>
				<p>
					By bubbling compressed air through liquid effluent in a tank, ATUs create a highly oxygenated (aerobic) environment for bacteria, which uses the organic matter as an energy source. In another stage bacteria and solids settle out of the wastewater and the cleaner effluent is distributed to a soil treatment system.
				</p>
				<p>
					ATUs are more complicated than septic tanks. In a septic tank, solids are constantly separating from liquid. As individual bacterial cells grow, they sink to the bottom, along with less decomposed solids, to form a layer of sludge. Floating materials, such as fats and toilet paper, form a scum layer at the top of the tank.
				</p>
				<p>
					In an ATU, the bubbler agitates the water so solids cannot settle out, and floating materials stay mixed in the liquid. Well-designed ATUs allow time and space for settling, while providing oxygen to the bacteria and mixing the bacteria and its food source (sewage). Any settled bacteria must be returned to the aerobic portion of the tank for mixing and treatment.
				</p>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
