import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss', '../app.component.scss']
})
export class FaqComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
