<div class="content-container">
	<div class="content">
		<a mat-raised-button color="accent" class="content-button" href="https://www.onlinerme.com/(S(hs3y1p5h1ybnnqhw2qwje11a))/contractorsearchproperty.aspx" target="_blank">
			Online RME
		</a>
		<br><br>
		<p>
			Directions:
		</p>
		<mat-list class="info-list">
			<mat-list-item *ngFor="let direction of rmeDirections; index as i">
				{{i + 1}}. {{direction}}
			</mat-list-item>
		</mat-list>
	</div>
</div>
<div class="content-container">
	<div class="content">
		<a mat-raised-button color="accent" class="content-button" href="https://gis.clark.wa.gov/mapsonline/index.cfm?" target="_blank">
			Clark County GIS
		</a>
		<br><br>
		<p>
			Directions:
		</p>
		<mat-list class="info-list">
			<mat-list-item *ngFor="let direction of gisDirections; index as i">
				{{i + 1}}. {{direction}}
			</mat-list-item>
		</mat-list>
	</div>
</div>