// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyAzSVGlzcroiirSBgpj-DNuSLVqlBa6zpc",
		authDomain: "aaaseptic.firebaseapp.com",
		databaseURL: "https://aaaseptic.firebaseio.com",
		projectId: "aaaseptic",
		storageBucket: "aaaseptic.appspot.com",
		messagingSenderId: "686069396713",
		appId: "1:686069396713:web:21acbc7b5710eb8c4cec78",
		measurementId: "G-XYHKC5MY3B"
	},
	captcha: {
		siteKey: '6Ld61c8ZAAAAAI_U8QbbHfJjXicGDUIqgmVNbVYA',
		size: 'normal',
		theme: 'light',
		lang: 'en'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
