import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Lead } from '../models/lead';

@Injectable({
	providedIn: 'root'
})
export class LeadService {

	constructor(private afs: AngularFirestore) {	}

	// TODO: Implement get when we build a ui for ourselves
	// get(): Observable<Lead[]> {}

	async create(lead: Lead) {
		lead.createdDate = new Date();

		await this.afs.collection<Lead>('leads').add(lead);
	}
}
