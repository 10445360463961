<div fxLayout="column" fxLayoutAlign="start stretch" class="aaa container">
	<header>
		<mat-toolbar
			color="primary"
			class="mat-elevation-z10"
			fxLayout="row">
			<div fxLayoutAlign="start center" class="logo-container" routerLink="about" href="/about" ngStyle.xs="position: absolute;">
				<img src="../assets/logo.png" class="logo"/>
			</div>
			<div fxLayoutAlign="start center" fxHide.lt-md>
				<a mat-button aria-label="About" routerLink="about" href="/about" class="no-select" routerLinkActive="active">
					About
				</a>
				<a mat-button aria-label="Systems" routerLink="systems" href="/systems" class="no-select" routerLinkActive="active">
					Systems
				</a>
				<a mat-button aria-label="Resources" routerLink="resources" href="/resources" class="no-select" routerLinkActive="active">
					Resources
				</a>
				<a mat-button aria-label="FAQ" routerLink="faq" href="/faq" class="no-select" routerLinkActive="active">
					FAQ
				</a>
				<a mat-button aria-label="Contact" routerLink="contact" href="/contact" class="no-select" routerLinkActive="active">
					Contact
				</a>
			</div>
			<div fxFlex fxLayoutAlign="end center">
				<div fxHide.xs class="phone" ngStyle.xs="margin-left: -40px; font-size: 16px;">
					<mat-icon class="align">phone</mat-icon>
					(360) 687-8960
				</div>
				<div fxHide.gt-sm fxLayoutAlign="end" style="padding-left: 20px;">
					<button mat-stroked-button class="menu-button"
						aria-label="Menu"
						[mat-menu-trigger-for]="navMenu">
						<mat-icon>menu</mat-icon> Menu
					</button>
				</div>
				<mat-menu #navMenu class="dark">
					<a mat-menu-item aria-label="About" routerLink="about" href="/about" class="no-select" routerLinkActive="active">
						About
					</a>
					<a mat-menu-item aria-label="Systems" routerLink="systems" href="/systems" class="no-select" routerLinkActive="active">
						Systems
					</a>
					<a mat-menu-item aria-label="Resources" routerLink="resources" href="/resources" class="no-select" routerLinkActive="active">
						Resources
					</a>
					<a mat-menu-item aria-label="FAQ" routerLink="faq" href="/faq" class="no-select" routerLinkActive="active">
						FAQ
					</a>
					<a mat-menu-item aria-label="Contact" routerLink="contact" href="/contact" class="no-select" routerLinkActive="active">
						Contact
					</a>
				</mat-menu>
			</div>
		</mat-toolbar>
	</header>
	<div fxLayout="column" fxLayoutAlign="top center" fxFlex>
		<!-- <div fxLayout.gt-md="row" fxLayout="column" fxLayoutAlign="end center" class="blurb-container"> -->
			<!-- <div  fxFlexFill> -->
		<div fxLayout="column" fxLayoutAlign="center stretch" class="top-banner">
			<div fxHide.gt-xs style="height:50px;"></div>
			<!-- <div fxFlex fxLayoutAlign="end center" fxHide.gt-sm class="phone" style="height:45px;">
				<mat-icon class="align">phone</mat-icon>
				(360) 687-8960
			</div> -->
			<h2>
				Septic Experts
			</h2>
			<p>
				AAA Septic Service is a local, family owned company, offering you over thirty years of experience.
			</p>
				<div fxHide.gt-xs class="phone" style="color: #000000;">
					<mat-icon class="align">phone</mat-icon>
					(360) 687-8960
				</div>
		</div>
			<!-- </div> -->
		<!-- </div> -->
		<!-- fxLayout.gt-sm="row"  -->
		<div fxLayout="column" fxLayoutAlign="center center">
			<router-outlet></router-outlet>
			<!-- fxLayout.gt-sm="column"  -->
			<!-- fxLayoutAlign.gt-sm="start stretch" -->

		</div>
	</div>
	<footer fxLayout="column" fxLayoutAlign="center stretch">
		<div fxLayout="row" fxLayoutAlign="center stretch" fxFill>
			<div fxLayout="column" fxLayoutAlign="top center" class="dual-section no-select" fxFlex="50">
				<h2>Services We Provide</h2>
				<mat-list class="info-list">
					<mat-list-item *ngFor="let service of services">
						<mat-icon mat-list-icon>arrow_right</mat-icon>
						{{service}}
					</mat-list-item>
				</mat-list>
			</div>
			<div fxLayout="column" fxLayoutAlign="top center" class="dual-section no-select" fxFlex="50">
				<h2>Service Areas</h2>
				<mat-list class="info-list">
					<mat-list-item *ngFor="let area of serviceAreas">
						<mat-icon mat-list-icon>arrow_right</mat-icon>
						{{area}}
					</mat-list-item>
				</mat-list>
			</div>
		</div>
		<mat-toolbar
			color="primary"
			class="mat-elevation-z10">
			<div fxLayout="row" fxLayoutAlign="center" fxFlex class="footer-links">
				<div fxHide.xs="true">
					<a routerLink="about" href="/about" routerLinkActive="active">About</a>
					<a routerLink="systems" href="/systems" routerLinkActive="active">Systems</a>
					<a routerLink="resources" href="/resources" routerLinkActive="active">Resources</a>
					<a routerLink="faq" href="/faq" routerLinkActive="active">FAQ</a>
					<a routerLink="contact" href="/contact" routerLinkActive="active">Contact</a>
				</div>
			</div>
			<div flexLayout="row" fxLayoutAlign="end" class="copy">© {{year}} AAA Septic</div>
		</mat-toolbar>
	</footer>
</div>
